<template>
  <teleport to="body">
    <transition name="fade" appear>
      <div v-if="visible" class="ui-modal">
        <div class="window-mask" @click="off" />
        <Flex alignCenter justifyCenter fullWidth class="modal-container">
          <Card float class="modal-content">
            <Flex align-center class="mb-6">
              <Icon
                v-if="titleIcon"
                :name="titleIcon"
                :color="titleIconColor ? titleIconColor : 'primary'"
                class="mr-1"
              />
              <Box flex="1">
                <Typography v-if="title" size="xl">{{ title }}</Typography>
                <slot name="title"></slot>
              </Box>
              <IconButton v-if="!hideClose" icon-name="close" @click="off" />
            </Flex>
            <slot />
            <Flex v-if="buttonLabel" justify-center class="mt-6">
              <Button
                width="120px"
                size="m"
                class="mx-2"
                variant="outlined"
                @click="off"
              >
                キャンセル
              </Button>
              <Button
                width="120px"
                size="m"
                class="mx-2"
                @click="$emit('click-button')"
              >
                {{ buttonLabel }}
              </Button>
            </Flex>
          </Card>
        </Flex>
      </div>
    </transition>
  </teleport>
  <slot name="activator" :on="on" />
</template>
<script>
import { defineComponent, computed } from 'vue';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import Button from '@/components/ui/Button.vue';
import Card from '@/components/ui/Card.vue';
import IconButton from '@/components/ui/IconButton.vue';
import Icon from '@/components/ui/Icon.vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  components: { Box, Button, Card, Flex, IconButton, Icon, Typography },
  props: {
    modelValue: Boolean,
    title: String,
    titleIcon: String,
    titleIconColor: String,
    buttonLabel: String,
    hideClose: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'click-button', 'close'],
  setup(props, { emit }) {
    const visible = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    });

    return {
      visible,
      off: () => {
        if (props.hideClose) {
          return;
        }
        visible.value = false;
        emit('close');
      },
      on: () => (visible.value = true)
    };
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/layout';
@import '@/styles/spacing';
@import '@/styles/_breakpoint.scss';

.ui-modal {
  font-family: var(--font-family);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-50-color);
  z-index: $z-index-modal;
}

.window-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: $z-index-modal;
}

.modal-container {
  height: 100%;
  padding: $spacing-base * 16 0;
}

.modal-content {
  position: relative;
  z-index: $z-index-modal + 1;
}

@media (max-width: $tablet) {
  .modal-content {
    width: 90%;
  }
}
</style>
