
import { defineComponent, computed, ref } from 'vue';
import { BrowserName, getBrowserName } from '@/common/function';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import BaseTextField from '@/components/ui/BaseTextField.vue';
import ErrorMessage from '@/components/ui/ErrorMessage.vue';
import IconButton from '@/components/ui/IconButton.vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'TextField',
  components: {
    BaseTextField,
    Box,
    ErrorMessage,
    Flex,
    IconButton,
    Typography
  },
  props: {
    modelValue: String,
    errorMessage: String,
    error: {
      type: Boolean,
      default: false
    },
    size: String,
    bold: Boolean,
    dense: Boolean,
    suffix: String,
    money: Boolean,
    type: String,
    clear: Boolean,
    min: String,
    max: String
  },
  emits: ['update:modelValue', 'input', 'keyup', 'blur', 'focus', 'clear'],
  setup(props, { emit }) {
    type delType = 'front' | 'back' | undefined;
    const inputValue = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const textFieldBody: any = ref(null);
    const random = (Math.random() * 11).toString().slice(-4);
    const textFieldId = new Date().getTime().toString() + random.toString();
    const isTextSelected = ref(false); // テキストが選択状態(select)かどうか
    const browser = getBrowserName();
    const beforeInputValue = ref('');
    const inputData = ref('');
    const isInput = ref(false);
    const isConverting = ref(false);
    const prevSelectionStart = ref();
    const prevSaelectionEnd = ref();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const addComma = (val: any) => {
      return /^[0-9]+$/.test(val)
        ? Number(val)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : '';
    };
    const init = () => {
      if (
        props.money &&
        inputValue.value &&
        inputValue.value.indexOf(',') === -1
      ) {
        setTimeout(() => {
          inputValue.value = addComma(inputValue.value);
          emit('update:modelValue', addComma(inputValue.value));
        });
      }
    };
    const convertToSingleByteNumbers = (
      target: HTMLInputElement,
      isComposing: boolean,
      deleteType: delType
    ) => {
      if (!props.money) return;

      if (
        isTextSelected.value &&
        !deleteType &&
        !/[0-9０-９]/.test(inputData.value)
      ) {
        // 文字列が選択されている状態での入力操作時、入力値に数字が含まれない場合は入力操作前の値を保持する
        target.value = beforeInputValue.value;
      } else {
        prevSelectionStart.value = undefined;
        prevSaelectionEnd.value = undefined;
      }

      // キャレットの位置を保存
      const bfLength = target.value?.length;
      let bfSelectionStr: number | undefined = undefined;
      let numFromEnd: number | undefined = undefined;
      if (textFieldBody.value && textFieldBody.value.$refs.baseTextFieldBody) {
        bfSelectionStr =
          textFieldBody.value.$refs.baseTextFieldBody.selectionStart;
        if (bfSelectionStr !== undefined && target.value) {
          numFromEnd = target.value?.length - bfSelectionStr;
        }
      }

      // 文字列削除操作時に削除対象がカンマの場合はカンマの次の文字を削除する
      let isDeletecomma = false;
      if (deleteType) {
        const size = target.value?.length;
        for (let i = 0; i < size; i++) {
          const bef = beforeInputValue.value.substring(
            beforeInputValue.value.length - i,
            beforeInputValue.value.length - i - 1
          );
          const aft = target.value.substring(
            target.value.length - i,
            target.value.length - i - 1
          );
          if (bef !== aft) {
            const selectionStart =
              textFieldBody.value.$refs.baseTextFieldBody.selectionStart;
            const selectionEnd =
              textFieldBody.value.$refs.baseTextFieldBody.selectionEnd;
            if (
              bef === ',' &&
              selectionStart === selectionEnd &&
              !isTextSelected.value
            ) {
              // 押下キーBackSpace、Deleteに応じて削除文字位置を調整
              const numFirst = deleteType === 'front' ? -1 : 0;
              const numSecond = deleteType === 'front' ? 0 : 1;
              target.value =
                target.value.substring(0, target.value.length - i + numFirst) +
                target.value.substring(target.value.length - i + numSecond);
              isDeletecomma = true;
            }
            break;
          }
        }
      }

      const textInput = document.getElementById(textFieldId);
      if (
        isComposing &&
        (browser === BrowserName.Chrome || browser === BrowserName.Edg)
      ) {
        isConverting.value = true;
        textInput?.blur(); // 全角入力時の変換タブを非表示にするためにフォーカスを外す
      }

      target.value = target.value + ','; // 末尾が全角数値の場合に発生する不具合回避処理

      // 全角数字を半角に変換、数値以外を削除
      const moneyValue = target.value.replace(/,/g, '').trim();
      let halfWidth = moneyValue.replace(/[０-９]/g, m =>
        '０１２３４５６７８９'.indexOf(m).toString()
      );
      halfWidth = halfWidth.replace(/\D+/g, '').trim();
      // 3桁カンマ付与
      target.value = addComma(halfWidth);
      inputValue.value = target.value;
      const afLength = target.value?.length;

      setTimeout(() => {
        if (
          isComposing &&
          (browser === BrowserName.Chrome || browser === BrowserName.Edg)
        ) {
          textInput?.focus(); // フォーカスを戻す
          isConverting.value = false;
        }

        // キャレットの位置を保存してあった位置に変更
        if (
          afLength &&
          bfLength &&
          textFieldBody.value.$refs.baseTextFieldBody &&
          numFromEnd !== undefined &&
          bfSelectionStr !== undefined
        ) {
          let afSelectionStr = afLength - numFromEnd;
          if (afSelectionStr < 0) afSelectionStr = 0;
          let adjustment = deleteType === 'back' && isDeletecomma ? 1 : 0;
          textFieldBody.value.$refs.baseTextFieldBody.setSelectionRange(
            afSelectionStr + adjustment,
            afSelectionStr + adjustment
          );
        }

        if (
          prevSelectionStart.value !== undefined &&
          prevSaelectionEnd.value !== undefined
        ) {
          textFieldBody.value.$refs.baseTextFieldBody.setSelectionRange(
            prevSelectionStart.value,
            prevSaelectionEnd.value
          );
        }

        emit('update:modelValue', inputValue.value);
      });
    };

    init();
    return {
      inputValue,
      textFieldBody,
      textFieldId,
      sizeClass: computed(() => props.size || 's'),
      denseClass: computed(() => (props.dense ? 'dense' : '')),
      onKeyDownHandler: (event: { target: HTMLInputElement }) => {
        if (
          props.money &&
          (browser === BrowserName.Chrome || browser === BrowserName.Edg)
        ) {
          isTextSelected.value =
            event.target.selectionStart !== event.target.selectionEnd;
          if (isTextSelected.value) {
            prevSelectionStart.value = event.target.selectionStart;
            prevSaelectionEnd.value = event.target.selectionEnd;
          }
        }
      },
      onKeyDownDeleteHandler: () => {
        //dummy
      },
      onBeforeinputHandler: (event: {
        target: HTMLInputElement;
        data: string;
      }) => {
        if (props.money) {
          beforeInputValue.value = event.target.value;
          inputData.value = event.data;
          if (browser === BrowserName.Safari) {
            isTextSelected.value =
              event.target.selectionStart !== event.target.selectionEnd;
            if (isTextSelected.value) {
              prevSelectionStart.value = event.target.selectionStart;
              prevSaelectionEnd.value = event.target.selectionEnd;
            }
          }
        }
      },
      onInputHandler: (event: {
        target: HTMLInputElement;
        isComposing: boolean;
        inputType: string;
        data: string;
      }) => {
        if (
          props.money &&
          (browser === BrowserName.Chrome || browser === BrowserName.Edg)
        ) {
          let deleteType: delType = undefined;
          if (event.inputType?.includes('deleteContentBackward')) {
            deleteType = 'front';
          } else if (event.inputType?.includes('deleteContentForward')) {
            deleteType = 'back';
          }

          if (!event.isComposing && /[０-９]/.test(event.data)) {
            // 全角数値を入力した際にevent.isComposingが半角モードでevent.dataが全角数値の
            // 状態となる場合があるため回避処理を実装
            event.target.value = event.target.value
              .replace(/[^0-9,]/g, '')
              .trim();
            inputValue.value = event.target.value;
          } else {
            convertToSingleByteNumbers(
              event.target,
              event.isComposing,
              deleteType
            );
          }
        }
        if (props.money && browser === BrowserName.Safari) isInput.value = true;
        emit('input', event);
      },
      onKeyupHandler: (event: {
        target: HTMLInputElement;
        isComposing: boolean;
        code: string;
      }) => {
        if (props.money && browser === BrowserName.Safari) {
          let deleteType: delType = undefined;
          if (event.code?.includes('Backspace')) {
            deleteType = 'front';
          } else if (event.code?.includes('Delete')) {
            deleteType = 'back';
          }
          if (isInput.value || deleteType) {
            convertToSingleByteNumbers(
              event.target,
              event.isComposing,
              deleteType
            );
          }
          isInput.value = false;
        }
        emit('keyup', event.target.value);
      },
      onBlurHandler: (event: { target: HTMLInputElement }) => {
        if (props.money && isConverting.value) return;
        emit('blur', event.target.value);
      },
      onFocus: (event: { target: HTMLInputElement }) => {
        if (props.money && isConverting.value) return;
        emit('focus', event.target.value);
      },
      onSelectHandler: () => {
        if (props.money) {
          isTextSelected.value = true;
        }
      },
      onClickHandler: (event: { target: HTMLInputElement }) => {
        if (props.money) {
          beforeInputValue.value = event.target.value;
        }
      }
    };
  }
});
