import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextField = _resolveComponent("BaseTextField")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_Box = _resolveComponent("Box")!
  const _component_Typography = _resolveComponent("Typography")!
  const _component_Flex = _resolveComponent("Flex")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-text-field", [_ctx.sizeClass, _ctx.denseClass, { suffix: _ctx.suffix, money: _ctx.money }]])
  }, [
    _createVNode(_component_Flex, { "align-end": "" }, {
      default: _withCtx(() => [
        _createVNode(_component_Box, {
          position: "relative",
          width: "100%"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BaseTextField, {
              modelValue: _ctx.inputValue,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
              error: !!_ctx.errorMessage || _ctx.error,
              size: _ctx.size,
              bold: _ctx.bold,
              disabled: _ctx.$attrs.disabled,
              type: _ctx.type,
              autocomplete: _ctx.$attrs.autocomplete,
              readonly: _ctx.$attrs.readonly,
              placeholder: _ctx.$attrs.placeholder,
              min: _ctx.type == 'number' ? _ctx.min : undefined,
              max: _ctx.type == 'number' ? _ctx.max : undefined,
              onKeydown: [
                _ctx.onKeyDownHandler,
                _withKeys(_ctx.onKeyDownDeleteHandler, ["delete"])
              ],
              onBeforeinput: _ctx.onBeforeinputHandler,
              onInput: _ctx.onInputHandler,
              onKeyup: _ctx.onKeyupHandler,
              onFocus: _ctx.onFocus,
              onBlur: _ctx.onBlurHandler,
              onSelect: _ctx.onSelectHandler,
              onClick: _ctx.onClickHandler,
              ref: "textFieldBody",
              id: _ctx.textFieldId
            }, null, 8, ["modelValue", "error", "size", "bold", "disabled", "type", "autocomplete", "readonly", "placeholder", "min", "max", "onKeydown", "onBeforeinput", "onInput", "onKeyup", "onFocus", "onBlur", "onSelect", "onClick", "id"]),
            (_ctx.clear)
              ? (_openBlock(), _createBlock(_component_IconButton, {
                  key: 0,
                  "icon-name": "close-circle",
                  class: "clear",
                  onClick: _cache[1] || (_cache[1] = 
            () => {
              _ctx.inputValue = '';
              _ctx.$emit('clear');
            }
          )
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_ctx.suffix)
          ? (_openBlock(), _createBlock(_component_Typography, {
              key: 0,
              class: "ml-2",
              "line-height": "1.3"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.suffix), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!!_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          class: "mt-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}