export const displayName = (
  nickName: string | undefined | null,
  lastName: string | undefined | null,
  firstName: string | undefined | null,
  unknownName = ''
): string | undefined | null => {
  // xxxxNameがundefined or nullになるのはユーザーが削除されている場合など
  // nickNameは未設定の場合は、空文字列("")であることもあるので注意 ※Null合体演算子(??)は使わない
  // 表示名の優先度は nickName > `${lastName} ${firstName}` > unknownName(ユーザー削除済みの規定値)
  if (nickName) {
    return nickName;
  } else if (lastName && firstName) {
    return `${lastName} ${firstName}`;
  }
  return unknownName;
};

export const BrowserName = {
  Edg: 'edg',
  Chrome: 'chrome',
  Safari: 'safari',
  Others: 'others'
};
export const getBrowserName = (): string => {
  const agent = window.navigator.userAgent.toLowerCase();
  if (agent.indexOf('msie') != -1 || agent.indexOf('trident') != -1) {
    return BrowserName.Others; // Internet Explorer
  } else if (agent.indexOf('edg') != -1 || agent.indexOf('edge') != -1) {
    return BrowserName.Edg;
  } else if (agent.indexOf('opr') != -1 || agent.indexOf('opera') != -1) {
    return BrowserName.Others; // Opera
  } else if (agent.indexOf('chrome') != -1) {
    return BrowserName.Chrome;
  } else if (agent.indexOf('safari') != -1) {
    return BrowserName.Safari;
  } else if (agent.indexOf('firefox') != -1) {
    return BrowserName.Others; // FireFox
  } else if (agent.indexOf('opr') != -1 || agent.indexOf('opera') != -1) {
    return BrowserName.Others; // Opera
  }
  return BrowserName.Others;
};

export const OSName = {
  Windows: 'windows',
  MacOS: 'chrome',
  Others: 'others'
};
export const getOSName = (): string => {
  const agent = window.navigator.userAgent.toLowerCase();
  if (agent.indexOf('windows nt') !== -1) {
    return OSName.Windows;
  } else if (agent.indexOf('android') !== -1) {
    return OSName.Others; // Android
  } else if (agent.indexOf('iphone') !== -1 || agent.indexOf('ipad') !== -1) {
    return OSName.Others; // iOS
  } else if (agent.indexOf('mac os x') !== -1) {
    return OSName.MacOS;
  }
  return OSName.Others;
};
