<template>
  <BaseRadio
    :checked="checked"
    :disabled="disabled"
    :label="label"
    :label-size="labelSize"
    :label-bold="labelBold"
  >
    <input
      v-model="checkedValue"
      type="radio"
      :value="value"
      :disabled="disabled"
      @change="onChange"
    />
  </BaseRadio>
</template>
<script>
import { defineComponent, computed } from 'vue';
import BaseRadio from '@/components/ui/BaseRadio.vue';

export default defineComponent({
  name: 'Radio',
  components: {
    BaseRadio
  },
  props: {
    modelValue: {
      type: [String, Number, Boolean]
    },
    value: {
      type: [String, Number, Boolean]
    },
    disabled: Boolean,
    label: String,
    labelSize: String,
    labelBold: Boolean
  },
  emits: ['change', 'update:modelValue'],
  setup(props, { emit }) {
    const checkedValue = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    });
    return {
      checkedValue,
      checked: computed(() => checkedValue.value === props.value),
      onChange: event => emit('change', event)
    };
  }
});
</script>
