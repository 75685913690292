import { computed, ComputedRef, Ref, ref } from 'vue';
import {
  AreaInfoIdEnum,
  ChangeCompanySettingsFormAggregationUnitEnum,
  CompanySettingsAggregationUnitEnum,
  UpdateCompanyStatusFormStatusEnum,
  CompanySettingsViewingTypeEnum,
  ChangeCompanySettingsFormViewingTypeEnum
} from '@/api/openapi';
import { CompanyApi } from '@/api';
import useModal from '@/composables/modal';
import useLoading from '@/composables/loading';
import { handleError } from '@/common/handleError';
import { VALIDATION_MESSAGE } from '@/common/validation';
import { toast } from '@/components/ui/Toast';
import { TOAST } from '@/common/constant';
import { WORKSPACE_ROUTES } from '@/router';
import { useRouter } from 'vue-router';

interface ICompanySettingValues {
  companyName: Ref<string>;
  isConversion15sec: Ref<boolean>;
  viewingType: Ref<CompanySettingsViewingTypeEnum>;
  initialDisplayAreaId: Ref<AreaInfoIdEnum | undefined>;
}

interface IUseCompanySetting extends ICompanySettingValues {
  fetch: () => Promise<void>;
  put: () => Promise<void>;
  openRemoveModal: () => void;
  isRemoveModalOpen: Ref<boolean>;
  removeCompany: ({
    companyId,
    workspaceId
  }: {
    companyId: number;
    workspaceId: number;
  }) => void;
  confirmCompanyName: Ref<string>;
  isRemoveCompanyLoading: Ref<boolean>;
  onRemoveCompanyInput: (companyName: string) => void;
  onCancelCompanyInput: (companyName: string) => void;
  confirmCompanyNameErorMessage: Ref<string>;
}

export const useCompanySetting = (companyId: number): IUseCompanySetting => {
  const companyName = ref('');
  const isConversion15sec = ref(false);
  const viewingType = ref(CompanySettingsViewingTypeEnum.Realtime);
  const initialDisplayAreaId = ref<AreaInfoIdEnum>();
  let aggregationUnit: ChangeCompanySettingsFormAggregationUnitEnum | undefined;
  const _convertUnitForPutRequestType = (
    unit: CompanySettingsAggregationUnitEnum
  ) => {
    switch (unit) {
      case CompanySettingsAggregationUnitEnum.Personal:
        return ChangeCompanySettingsFormAggregationUnitEnum.Personal;
      case CompanySettingsAggregationUnitEnum.Household:
        return ChangeCompanySettingsFormAggregationUnitEnum.Household;
      default:
        return undefined;
    }
  };
  const _convertViewingType = (value: CompanySettingsViewingTypeEnum) => {
    switch (value) {
      case CompanySettingsViewingTypeEnum.Realtime:
        return ChangeCompanySettingsFormViewingTypeEnum.Realtime;
      case CompanySettingsViewingTypeEnum.Total:
        return ChangeCompanySettingsFormViewingTypeEnum.Total;
      default:
        return ChangeCompanySettingsFormViewingTypeEnum.Realtime;
    }
  };
  const fetch = async () => {
    try {
      const { data } = await CompanyApi.getCompaniesCompanyIdSettings(
        companyId
      );
      companyName.value = data.companyName;
      isConversion15sec.value = data.isConversion15sec;
      viewingType.value =
        data.viewingType || CompanySettingsViewingTypeEnum.Realtime;
      initialDisplayAreaId.value = data.initialDisplayAreaInfo.id;
      aggregationUnit = _convertUnitForPutRequestType(data.aggregationUnit);
    } catch (e) {
      if (e.status !== 403) {
        handleError(e);
      }
    }
  };
  const put = async () => {
    if (
      aggregationUnit !== undefined &&
      initialDisplayAreaId.value !== undefined
    ) {
      try {
        await CompanyApi.putCompaniesCompanyIdSettings(companyId, {
          companyName: companyName.value,
          aggregationUnit,
          isConversion15sec: isConversion15sec.value,
          viewingType: _convertViewingType(viewingType.value),
          initialDisplayArea: initialDisplayAreaId.value
        });
        toast({
          title: TOAST.success.title,
          message: TOAST.success.message,
          variant: 'success'
        });
      } catch (e) {
        handleError(e);
      }
    }
  };

  const router = useRouter();
  const { isModalOpen: isRemoveModalOpen, openModal } = useModal(false);
  const openRemoveModal = () => {
    openModal();
  };
  const confirmCompanyName = ref('');
  const confirmCompanyNameErorMessage = ref('');
  const removeCompany = async ({
    companyId,
    workspaceId
  }: {
    companyId: number;
    workspaceId: number;
  }) => {
    try {
      await CompanyApi.updateCompaniesCompanyIdStatus(companyId, {
        status: UpdateCompanyStatusFormStatusEnum.Deleted
      });
      toast({
        title: 'カンパニーを削除しました',
        variant: 'success'
      });
      isRemoveModalOpen.value = false;
      router.push({
        name: WORKSPACE_ROUTES.top,
        params: { workspaceId }
      });
    } catch (e) {
      toast({
        title: 'カンパニーの削除エラー',
        message: 'カンパニーが削除できませんでした。',
        variant: 'error'
      });
    }
  };
  const onRemoveCompanyInput = (confirmCompanyName: string) => {
    if (confirmCompanyName !== companyName.value) {
      confirmCompanyNameErorMessage.value = '一致しません';
    } else {
      confirmCompanyNameErorMessage.value = '';
    }
  };
  const onCancelCompanyInput = () => {
    confirmCompanyName.value = '';
    confirmCompanyNameErorMessage.value = '';
  };
  const [isRemoveCompanyLoading] = useLoading(removeCompany);
  return {
    companyName,
    isConversion15sec,
    viewingType,
    initialDisplayAreaId,
    fetch,
    put,
    openRemoveModal,
    isRemoveModalOpen,
    removeCompany,
    confirmCompanyName,
    isRemoveCompanyLoading,
    onRemoveCompanyInput,
    onCancelCompanyInput,
    confirmCompanyNameErorMessage
  };
};

type ValidateReturnType = string | undefined;

interface IUseValidate {
  validateCompanyName: ComputedRef<ValidateReturnType>;
  validateArea: ComputedRef<ValidateReturnType>;
  validateAll: ComputedRef<boolean>;
}

export const useValidate = (values: ICompanySettingValues): IUseValidate => {
  const COMPANY_MAX_LENGTH = 256;

  const _validateCompanyName = () => {
    const companyName = values.companyName.value;
    if (companyName.length === 0) {
      return VALIDATION_MESSAGE.required;
    } else if (companyName.length > COMPANY_MAX_LENGTH) {
      return VALIDATION_MESSAGE.maxN(COMPANY_MAX_LENGTH);
    } else {
      return undefined;
    }
  };

  const _validateArea = () => {
    const areaId = values.initialDisplayAreaId.value;
    if (areaId === undefined) {
      return VALIDATION_MESSAGE.required;
    } else {
      return undefined;
    }
  };

  const validateCompanyName = computed(() => {
    return _validateCompanyName();
  });

  const validateArea = computed(() => {
    return _validateArea();
  });

  const validateAll = computed(() => {
    return (
      validateCompanyName.value === undefined &&
      validateArea.value === undefined
    );
  });

  return {
    validateCompanyName,
    validateArea,
    validateAll
  };
};
