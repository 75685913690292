<template>
  <Flex align-center inline class="ui-radio" v-ripple>
    <div class="ui-radio-view" :class="{ checked, disabled }"></div>
    <Label
      v-if="label"
      class="ui-radio-label ml-1"
      :bold="labelBold"
      :size="labelSize"
      :class="{ disabled }"
      :disabled="disabled"
    >
      {{ label }}
    </Label>
    <slot />
  </Flex>
</template>
<script>
import { defineComponent } from 'vue';
import Flex from '@/components/layout/Flex.vue';
import Label from '@/components/ui/Label.vue';

export default defineComponent({
  name: 'BaseRadio',
  props: {
    checked: Boolean,
    disabled: Boolean,
    label: String,
    labelSize: String,
    labelBold: Boolean
  },
  components: { Flex, Label }
});
</script>
<style lang="scss">
.ui-radio {
  position: relative;

  input {
    position: absolute;
    display: inline-block;
    opacity: 0;
    width: 100%;
    height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover:not(:disabled) {
      cursor: pointer;
    }
  }
}

.ui-radio-view {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid var(--border-color);
  border-radius: 50%;

  &::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }

  &.checked {
    background-color: var(--background-focus-color);
    border-color: var(--border-focus-color);
    &::after {
      background-color: var(--contrast-color);
    }
  }

  &.disabled {
    background-color: var(--background-disabled-color);
    border-color: var(--border-disabled-color);
    &.checked {
      background-color: var(--background-disabled-color);
      &::after {
        background-color: var(--form-disabled-color);
      }
    }
  }
}

.ui-radio-label {
  position: relative;
  top: -1px;
}
</style>
