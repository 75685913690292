import { CompanyApi, DefaultApi } from '@/api';
import {
  AreaInfo,
  AreaInfoIdEnum,
  CompanySettings,
  SelectableAreasList
} from '@/api/openapi';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';
import { COMPANY_ROUTES, SETTINGS_ROUTES } from '@/router';
import { useUserInfoStore } from '@/store/userInfo';
import { defineStore } from 'pinia';
import { RouteLocationNormalized } from 'vue-router';

export type AreaOptions = Array<SelectableAreasList>;

export const useAreaOptions = defineStore('area', {
  state: () => ({
    selectOptions: [] as AreaOptions,
    workspaceId: undefined as number | undefined,
    isLoading: false as boolean,
    isValidDefault: true as boolean,
    suppressToast: false as boolean
  }),
  actions: {
    async fetchWithWorkspaceId(
      workspaceId: number,
      route: RouteLocationNormalized,
      companySetting?: CompanySettings,
      area?: AreaInfoIdEnum,
      isMain?: boolean
    ): Promise<AreaInfoIdEnum | undefined> {
      const fetchCompanySetting = async () => {
        try {
          let setting: CompanySettings;
          const companyId = parseInt(route.params.companyId.toString());
          if (companySetting) {
            setting = companySetting;
          } else if (companyId) {
            const _setting = await CompanyApi.getCompaniesCompanyIdSettings(
              companyId
            );
            setting = _setting.data;
          } else {
            return;
          }
          this.isValidDefault =
            this.option(setting.initialDisplayAreaInfo.id) !== undefined;
          const areaId =
            this.option(area) !== undefined
              ? area
              : this.isValidDefault
              ? setting.initialDisplayAreaInfo.id
              : undefined;
          if (
            !this.isValidDefault &&
            (route.name === SETTINGS_ROUTES.basic ||
              route.name === COMPANY_ROUTES.top) &&
            !this.suppressToast
          ) {
            toast({
              title: 'エリアのオプション契約が変更されました',
              message:
                '使用可能なエリアからエリアを選択して設定を保存してください',
              variant: 'error'
            });
          }
          this.suppressToast = false;
          return areaId ?? this.firstOption;
        } catch (e) {
          toast({
            variant: 'error',
            title: e.message,
            message: 'カンパニー設定取得に失敗しました'
          });
          return;
        }
      };

      this.workspaceId = workspaceId;
      try {
        this.isLoading = true;
        const result = await DefaultApi.getWorkspacesWorkspaceIdContractAreas(
          workspaceId,
          isMain
        );
        this.selectOptions = result.data.list;
        const setting = await fetchCompanySetting();
        this.isLoading = false;
        return setting;
      } catch (e) {
        handleError(e);
        this.isLoading = false;
      }
    },
    async fetch(
      route: RouteLocationNormalized,
      companySetting?: CompanySettings,
      area?: AreaInfoIdEnum,
      isMain?: boolean
    ): Promise<AreaInfoIdEnum | undefined> {
      const userInfoStore = useUserInfoStore();
      await userInfoStore.fetchJoinableCompanies();
      const workspace = userInfoStore.currentWorkspaceFromRoute(route);
      if (workspace) {
        return await this.fetchWithWorkspaceId(
          workspace.workspaceId,
          route,
          companySetting,
          area,
          isMain
        );
      }
    },
    onChangeDefault() {
      this.isValidDefault = true;
    }
  },
  getters: {
    options: (state): Array<AreaInfo> => {
      return state.selectOptions.flatMap(options => options.areas);
    },
    firstOption(): AreaInfoIdEnum | undefined {
      const options = this.options;
      if (options.length > 0) return options[0].id;
    },
    option() {
      return (val?: AreaInfoIdEnum): AreaInfo | undefined => {
        return this.options.find(option => option.id === val);
      };
    },
    optionByName() {
      return (val: string): AreaInfo | undefined => {
        return this.options.find(option => option.name === val);
      };
    }
  }
});
