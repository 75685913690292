import { toast } from '@/components/ui/Toast';
import axios, { AxiosError } from 'axios';

export const handleError = (
  e: Error | AxiosError,
  _title?: string,
  _message?: string
): void => {
  const isAxios = axios.isAxiosError(e);
  const title = isAxios ? 'エラーが発生しました。' : e.name;
  const message = isAxios ? e.response?.data.msg : e.message;
  if (axios.isAxiosError(e))
    toast({
      title: _title ?? title,
      message: _message ?? message,
      variant: 'error'
    });
};
