<template>
  <Modal v-model="isModalOpen" :title="title">
    <Box width="471px">
      <Typography text-align="center">
        <template v-if="target">
          <span class="remove-target-name">{{ `「${target}」` }}</span>
          <span v-if="deleteMessage">{{ deleteMessage }}</span>
          <span v-else>{{ 'を削除します' }}</span>
        </template>
        <template v-else>
          <span v-if="deleteMessage">{{ deleteMessage }}</span>
          <span v-else>{{ '削除します' }}</span>
        </template>
      </Typography>
      <Flex
        v-if="warningMessage"
        align-center
        justify-center
        class="mt-6 modal-warning-message"
      >
        <Icon name="exclamation" color="error" class="mr-2" />
        <Typography size="l" bold>
          {{ warningMessage }}
        </Typography>
      </Flex>
      <Typography
        v-if="caption"
        size="s"
        color="grey"
        text-align="center"
        line-height="1.5"
        class="mt-6"
      >
        <span v-html="caption" />
      </Typography>
      <slot />
    </Box>
    <Flex justify-center class="mt-6">
      <Button
        variant="outlined"
        color="grey"
        width="120px"
        size="m"
        class="mx-2"
        :disabled="loading"
        @click="(isModalOpen = false), onCancelHandler()"
      >
        キャンセル
      </Button>
      <Button
        color="red"
        width="120px"
        size="m"
        class="mx-2"
        :loading="loading"
        :disabled="disabled"
        @click="onRemoveHandler"
      >
        削除する
      </Button>
    </Flex>
  </Modal>
</template>
<script>
import { defineComponent, computed } from 'vue';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import Button from '@/components/ui/Button.vue';
import Icon from '@/components/ui/Icon.vue';
import Modal from '@/components/ui/Modal.vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'RemoveModal',
  components: { Box, Button, Flex, Icon, Modal, Typography },
  props: {
    title: String,
    target: String,
    deleteMessage: String,
    modelValue: Boolean,
    warningMessage: String,
    caption: String,
    loading: Boolean,
    disabled: Boolean
  },
  emits: ['remove', 'cancel', 'update:modelValue'],
  setup(props, { emit }) {
    const isModalOpen = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    });
    const onRemoveHandler = () => emit('remove');
    const onCancelHandler = () => emit('cancel');
    return {
      isModalOpen,
      onRemoveHandler,
      onCancelHandler
    };
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/spacing';
.remove-target-name {
  font-weight: 700;
}
.modal-warning-message {
  background-color: var(--red-blue_red-1);
  padding: $spacing-base;
}
</style>
